*,
*::before,
*::after {
  box-sizing: border-box;
  border: 0;
  margin: 0;
}

:root {
  --column-padding: 0 35px 0 35px;
  --font-color: black;
  --link-margin: 0.5rem;
  --recipe-font-size: 0.9rem;
}

body {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 20px;
  height: 100vh;
  margin: 0;
  padding: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: flex;
  height: calc(100vh - 4rem - 50px);
}

/* Link tag styling */
a {
  color: var(--font-color);
  display: block;
  margin-top: var(--link-margin);
  margin-bottom: var(--link-margin);
  text-decoration: none;
}
a.active {
  text-decoration: underline;
}

/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 7.5px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
}

/* Column expand from left to right animation */
.expand-right {
  animation: expandRight 0.15s linear;
}

@keyframes expandRight {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

[data-classification="meat"] {
  color: #ff0000;
}

[data-classification="seafood"] {
  color: #0040ff;
}

[data-classification="vegan"] {
  color: #1ba500;
}

/* Hide left chevron icon on normal screen sizes */
.fa-solid {
  display: none;
}

.fa-solid:hover {
  cursor: pointer;
}
